<template>
    <v-container>

        <div class="chart_Layout"
            v-if="trendData.temp && trendData.temp.length > 1">
            <div class="mt-2"> 
                <button class="reset_btn mr-1" @click="resetGraph()">
                    <v-icon color="white" class="mdi-18px">
                        mdi-refresh
                    </v-icon>
                </button>
                <span class="title ">온도 / 습도 / 외기온도</span>
            </div>
            <LineChartGenerator
                id="myTempChart"
                ref="myChart"
                :chart-options="multiOptions"
                :chart-data="chartTemp"
                :height="300"
            />
        </div>
        
        <div class="chart_Layout"
            v-if="trendData.co2 && trendData.co2.length > 1">
            <div> 
                <button class="reset_btn mr-1" @click="resetGraph()">
                    <v-icon color="white" class="mdi-18px">
                        mdi-refresh
                    </v-icon>
                </button>
                <span class="title">이산화탄소</span>
            </div>
            <LineChartGenerator
                id="myChart"
                ref="myChart"
                :chart-options="options"
                :chart-data="chartCo2"
                :height="200" 
            />
        </div>

        <div class="chart_Layout"
            v-if="trendData.nh3 && trendData.nh3.length > 1">
            <div> 
                <button class="reset_btn mr-1" @click="resetGraph()">
                    <v-icon color="white" class="mdi-18px">
                        mdi-refresh
                    </v-icon>
                </button>
                <span class="title">암모니아</span>
            </div>
            <LineChartGenerator
                id="myChart"
                ref="myChart"
                :chart-options="options"
                :chart-data="chartNh3"
                :height="200" 
            />
        </div>

        <div class="chart_Layout"
            v-if="trendData.h2s && trendData.h2s.length > 1">
            <div> 
                <button class="reset_btn mr-1" @click="resetGraph()">
                    <v-icon color="white" class="mdi-18px">
                        mdi-refresh
                    </v-icon>
                </button>
                <span class="title">황화수소</span>
            </div>
            <LineChartGenerator
                id="myChart"
                ref="myChart"
                :chart-options="options"
                :chart-data="chartH2s"
                :height="200" 
            />
        </div>

        <div class="chart_Layout"
            v-if="trendData.temp_avg && trendData.temp_avg.length > 1">
            <div> 
                <button class="reset_btn mr-1" @click="resetGraph()">
                    <v-icon color="white" class="mdi-18px">
                        mdi-refresh
                    </v-icon>
                </button>
                <span class="title">온도</span>
            </div>
            <LineChartGenerator
                :chart-options="Dailyoptions"
                :chart-data="dailyChartTemp"
                :height="200"
            />
        </div>

        <div class="chart_Layout"
            v-if="trendData.w_temp_avg && trendData.w_temp_avg.length > 1">
            <div> 
                <button class="reset_btn mr-1" @click="resetGraph()">
                    <v-icon color="white" class="mdi-18px">
                        mdi-refresh
                    </v-icon>
                </button>
                <span class="title">외기온도</span>
            </div>
            <LineChartGenerator
                :chart-options="Dailyoptions"
                :chart-data="dailyChartwTemp"
                :height="200"
            />
        </div>

        <div class="chart_Layout"
            v-if="trendData.hum_avg && trendData.hum_avg.length > 1">
            <div> 
                <button class="reset_btn mr-1" @click="resetGraph()">
                    <v-icon color="white" class="mdi-18px">
                        mdi-refresh
                    </v-icon>
                </button>
                <span class="title">습도</span>
            </div>
            <LineChartGenerator
                :chart-options="Dailyoptions"
                :chart-data="dailyChartHum"
                :height="200"
            />
        </div>

        <div class="chart_Layout"
            v-if="trendData.co2_avg && trendData.co2_avg.length > 1">
            <div> 
                <button class="reset_btn mr-1" @click="resetGraph()">
                    <v-icon color="white" class="mdi-18px">
                        mdi-refresh
                    </v-icon>
                </button>
                <span class="title">이산화탄소</span>
            </div>
            <LineChartGenerator
                :chart-options="Dailyoptions"
                :chart-data="dailyChartCo2"
                :height="200"
            />
        </div>

        <div class="chart_Layout"
            v-if="trendData.nh3_avg && trendData.nh3_avg.length > 1">
            <div> 
                <button class="reset_btn mr-1" @click="resetGraph()">
                    <v-icon color="white" class="mdi-18px">
                        mdi-refresh
                    </v-icon>
                </button>
                <span class="title">암모니아</span>
            </div>
            <LineChartGenerator
                :chart-options="Dailyoptions"
                :chart-data="dailyChartNh3"
                :height="200"
            />
        </div>

        <div class="chart_Layout"
            v-if="trendData.h2s_avg && trendData.h2s_avg.length > 1">
            <div> 
                <button class="reset_btn mr-1" @click="resetGraph()">
                    <v-icon color="white" class="mdi-18px">
                        mdi-refresh
                    </v-icon>
                </button>
                <span class="title">황화수소</span>
            </div>
            <LineChartGenerator
                :chart-options="Dailyoptions"
                :chart-data="dailyChartH2s"
                :height="200"
            />
        </div>
    </v-container>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import zoomPlugin from 'chartjs-plugin-zoom';

import {
Chart as ChartJS,
Title,
Tooltip,
Legend,
LineElement,
LinearScale,
CategoryScale,
PointElement,
Filler,
} from 'chart.js'

ChartJS.register(
Title,
Tooltip,
Legend,
LineElement,
LinearScale,
CategoryScale,
PointElement,
Filler,
zoomPlugin
)

export default {
    name: 'LineChart',

    components: {
        LineChartGenerator,
    },

    props: {
        trendData: {
            type: Object,
        },
    },
    
    data: () => ({
        chartTemp: { },
        // chartHum: { },
        chartCo2: { },
        chartNh3: { },
        chartH2s: { },

        dailyChartTemp: { },
        dailyChartwTemp: { },
        dailyChartHum: { },
        dailyChartCo2: { },
        dailyChartNh3: { },
        dailyChartH2s: { },

        multiOptions:{
            responsive: true,
            maintainAspectRatio: false,
            animation: {
                duration: 1000
            },
            scales: {
                x: {
                    grid: {
                        drawOnChartArea: false // 이 축의 그리드 라인은 숨기기
                    },
                    ticks: {
                        // 보이는 갯수 제한하기 autoSkip, maxTrickLimit 
                        autoSkip: true,
                        maxTicksLimit:5,
                        maxRotation: 0,
                        minRotation: 0,
                        display: true,
                        padding: 3,
                        font: {
                            size: 9,
                        }
                    }
                },
                'y-axis-1': {
                    type: 'linear',
                    position: 'left',
                    ticks: {
                        beginAtZero: true,
                        suggestedMax: 100
                    },
                    title: {
                        display: false,
                    },
                    grid: {
                        drawOnChartArea: false // 이 축의 그리드 라인은 숨기기
                    }
                },
                'y-axis-2': {
                    type: 'linear',
                    position: 'right',
                    ticks: {
                        beginAtZero: true,
                        suggestedMax: 40
                    },
                    title: {
                        display: true,
                    },
                    grid: {
                        drawOnChartArea: false // 이 축의 그리드 라인은 숨기기
                    }
                },
                'y-axis-3': {
                    grid: {
                        drawOnChartArea: false // 이 축의 그리드 라인은 숨기기
                    },
                    display:false,
                }
            },
            interaction: {
                mode: 'nearest',
                axis: 'x',
                intersect: false
            },
            elements: {
                line: {	/* 선 옵션 */
                    borderCapStyle: 'round',
                    borderDashOffset: 0,
                    borderJoinStyle: 'round',	
                    borderWidth: 2.5,
                    capBezierPoints: true,
                    // fill: true,
                    tension: 0.4
                },
                point: {	/* 표시자 옵션 */
                    pointStyle: 'rectRounded',
                    pointRadius: 0, 
                    lineTension:1, 
                    pointBorderWidth: 0, 
                },
            },
            plugins: {
            zoom: {
                zoom: {
                    // 전체 동기화 zoom
                    onZoomComplete({chart}) {
                        for (const k of Object.keys(ChartJS.instances)) {
                            if (k !== chart.id) {
                                const c = ChartJS.instances[k];
                                c.config.options.scales.x.min = Math.trunc(chart.scales.x.min);
                                c.config.options.scales.x.max = Math.trunc(chart.scales.x.max);
                                c.update();
                            }
                    }},
                // 전체 동기화 zoom end
                drag: {
                    enabled: true
                },
                wheel: {
                    enabled: true,
                },
                pinch: {
                    enabled: true
                },
                mode: 'x',
                },
                animation: {
                    duration: 1000,
                    easing: 'easeOutCubic'
                }
            },
            legend: {
                display: true,
                position: "top",
                labels: {
                    usePointStyle: true,
                    generateLabels: function(chart) {
                        const data = chart.data;
                        return data.datasets.map((dataset, i) => {
                            return {
                                text: dataset.label,
                                fillStyle: dataset.borderColor,
                                strokeStyle: dataset.borderColor,
                                lineWidth: 1,
                                hidden: !chart.isDatasetVisible(i),
                                datasetIndex: i, // 여기서 datasetIndex를 설정하여 클릭 이벤트를 유지합니다.
                                pointStyle: 'line'
                            };
                        });
                    }
                },
                fullSize: true,
                align: "left"
            },
            tooltip: {
                y: [{
                backgroundColor: '#ACB8C0',
                caretSize: 4,
                cornerRadius: 6,
                borderWidth: 0,
                bodyFont: {
                    size: 12
                }
                }]
            },
            hover: {
                animationDuration: 400,
                axis: 'x',
                intersect: true,
                mode: 'label',
                onHover: null
            },
        },
        },
        options: {
            interaction: {
                mode: 'nearest',
                axis: 'x',
                intersect: false
                },
            elements: {
                line: {	/* 선 옵션 */
                    borderCapStyle: 'round',
                    borderDashOffset: 0,
                    borderJoinStyle: 'round',	
                    borderWidth: 1.5,
                    capBezierPoints: true,
                    fill: true,
                    tension: 0.4
                },
                point: {	/* 표시자 옵션 */
                    pointStyle: 'rectRounded',
                    pointRadius: 1, 
                    lineTension:1, 
                    pointBorderWidth: 0, 
                },
            },
            plugins: {
                zoom: {
                    zoom: {
                        drag: {
                            enabled: true
                        },
                        wheel: {
                            enabled: true,
                        },
                        pinch: {
                            enabled: true
                        },
                        mode: 'x',
                        },
                        animation: {
                            duration: 1000,
                            easing: 'easeOutCubic'
                        }
                },
                legend: {
                    display: false,
                    position: "top",
                labels: {
                    boxWidth: 1,
                    padding: 1,
                    pointStyle: "circle",
                    font: {
                        size: 13
                    }
                },
                    fullSize: true,
                    align: "left"
                },
                tooltip: {
                    y: [{
                    backgroundColor: '#ACB8C0',
                    caretSize: 4,
                    cornerRadius: 6,
                    borderWidth: 0,
                    bodyFont: {
                        size: 12
                    }
                    }]
                },
                hover: {
                    animationDuration: 400,
                    axis: 'x',
                    intersect: true,
                    mode: 'label',
                    onHover: null
                },
            },
            scales: {
                x: {
                    grid: {
                        display: false
                    },
                    ticks: {
                        // 보이는 갯수 제한하기 autoSkip, maxTrickLimit 
                        autoSkip: true,
                        maxTicksLimit:5,
                        maxRotation: 0,
                        minRotation: 0,
                        display: true,
                        padding: 3,
                        font: {
                            size: 9,
                        }
                    },
                },
                y: {
                    afterFit(scale) {       // ticks 표시영역 폭 통일 
                        scale.width = 50;
                    },

                    ticks: {
                        display:true,
                        padding: 5,
                        font: {
                            size: 12,
                        }
                    },
                // y축 스타일 지정
                grid: {
                    display:false,
                    color: "#e5e5e5",
                    lineWidth: 1
                },
                fontSize:9,
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            animation: {
                duration: 5000
            }
        },
        Dailyoptions: {
            elements: {
                line: {	/* 선 옵션 */
                    borderCapStyle: 'round',	/* 선 마감: butt, round, square */
                    borderDashOffset: 0,
                    borderJoinStyle: 'round',	/* 꺾이는 부분 모양: miter, bevel, round */
                    borderWidth: 3.0,
                    capBezierPoints: true,
                    fill: true,	/* 선 밑 공간 색으로 채우기 */
                    tension: 0.4
                },
                point: {	/* 표시자 옵션 */
                    pointStyle: 'circle',	/* circle, cross, corssRot, dash, line, rect, rectRounded, rectRot, start, triangle */
                    pointRadius: 2, //point 없게 만들기 0으로 설정
                    lineTension:1, //선 곡선모양 0이면 직선
                    pointBorderWidth: 2, //포인터 보더사이즈
                },
            },
            plugins: {
                legend: {
                    display: false,
                    position: "top",
                    labels: {
                        boxWidth: 1,
                        padding: 5,
                        // usePointStyle: true,
                        pointStyle: "circle",
                        font: {
                            size: 13
                        }
                    },
                    fullSize: true,
                    align: "left"
                },
                tooltip: {
                    backgroundColor: '#ACB8C0',
                    caretSize: 4,
                    cornerRadius: 6,
                    borderWidth: 0,
                    bodyFont: {
                        size: 12
                    }
                },
                hover: {
                    animationDuration: 400,
                    axis: 'x',
                    intersect: true,
                    mode: 'label',
                    onHover: null
                },
            },
            scales: {
                x: {
                    grid: {
                        display: true,
                    },
                    ticks: {
                        display:true,
                        padding: 3,
                        font: {
                            size: 12,
                        }
                    },
                },

                y: {
                    afterFit(scale) {       // ticks 표시영역 폭 통일 
                        scale.width = 50;
                    },
                    ticks: {
                        display:true,
                        padding: 5,
                        font: {
                            size: 10,
                        }
                    },
                // y축 스타일 지정
                grid: {
                    drawBorder: true,
                    color: "#e5e5e5",
                    lineWidth: 1
                },
                fontSize:9,
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            animation: {
                duration: 5000
            },
        },
    }),
    watch:{
        trendData: function(){         
            // 시간별
            this.chartTemp = {
                labels: this.trendData.time_temp,

                datasets:[
                    {
                        label:'온도',
                        data: this.trendData.temp,
                        yAxisID: 'y-axis-1',
                        borderColor: '#8CC0DE',
                    },
                    {
                        label:'습도',
                        data: this.trendData.hum,
                        borderColor: '#CCEEBC',
                        yAxisID: 'y-axis-2',
                    },
                    {
                        label:'외기온도',
                        data: this.trendData.w_temp,
                        borderColor: '#51829B',
                        yAxisID: 'y-axis-3',
                    },
                ],
            },
            this.chartCo2 = {
                labels: this.trendData.time_temp,

                datasets:[{
                    label:'이산화탄소',
                    data: this.trendData.co2,
                    backgroundColor: (ctx) => {
                        const canvas = ctx.chart.ctx;
                        const gradient = canvas.createLinearGradient(0,0,0,150);

                        gradient.addColorStop(0,  "rgba(168, 217, 246, 0.5)");
                        gradient.addColorStop(.5, "rgba(168, 217, 246, 0.4)");
                        gradient.addColorStop(1, "rgba(168, 217, 246, 0)");

                        return gradient;
                    },
                    borderColor: '#a8d9f6',
                },],
            },
            this.chartNh3 = {
                labels: this.trendData.time_temp,

                datasets:[{
                    label:'암모니아',
                    data: this.trendData.nh3,
                    backgroundColor: (ctx) => {
                        const canvas = ctx.chart.ctx;
                        const gradient = canvas.createLinearGradient(0,0,0,150);

                        gradient.addColorStop(0,  "rgba(26, 101, 158, 0.5)");
                        gradient.addColorStop(.5, "rgba(26, 101, 158, 0.4)");
                        gradient.addColorStop(1, "rgba(26, 101, 158, 0)");

                        return gradient;
                    },
                    borderColor: '#1a659e',
                },],
            },
            this.chartH2s = {
                labels: this.trendData.time_temp,

                datasets:[{
                    label:'황화수소',
                    data: this.trendData.h2s,
                    backgroundColor: (ctx) => {
                        const canvas = ctx.chart.ctx;
                        const gradient = canvas.createLinearGradient(0,0,0,150);

                        gradient.addColorStop(0,  "rgba(224, 251, 252, 0.5)");
                        gradient.addColorStop(.5, "rgba(224, 251, 252, 0.4)");
                        gradient.addColorStop(1, "rgba(224, 251, 252, 0)");

                        return gradient;
                    },
                    borderColor: '#49a078',
                },],
            },
            // 일별
            this.dailyChartTemp = {
                labels: this.trendData.time_array,

                datasets:[{
                    label:'평균',
                    data: this.trendData.temp_avg,
                    backgroundColor: "rgba(198, 252, 236, 0.1)",
                    borderColor: '#00B9C1',
                },
                {
                    label:'최고',
                    fill: false,
                    data: this.trendData.temp_high,
                    borderColor:"rgba(255, 0, 0, 0.4)",
                    tension: 0.1,
                    borderDash: [3, 3],
                },
                {
                    label:'최저',
                    data: this.trendData.temp_low,
                    fill: false,
                    borderColor: "rgba(0, 0, 255, 0.4)",
                    tension: 0.1,
                    borderDash: [3, 3],
                }]
            },
            this.dailyChartwTemp = {
                labels: this.trendData.time_array,

                datasets:[{
                    label:'평균',
                    data: this.trendData.w_temp_avg,
                    backgroundColor: "rgba(198, 252, 236, 0.1)",
                    borderColor: '#51829B',
                },
                {
                    label:'최고',
                    fill: false,
                    data: this.trendData.w_temp_high,
                    borderColor:"rgba(255, 0, 0, 0.4)",
                    tension: 0.1,
                    borderDash: [3, 3],
                },
                {
                    label:'최저',
                    data: this.trendData.w_temp_low,
                    fill: false,
                    borderColor: "rgba(0, 0, 255, 0.4)",
                    tension: 0.1,
                    borderDash: [3, 3],
                }]
            },
            this.dailyChartHum = {
                labels: this.trendData.time_array,

                datasets:[{
                    label:'평균',
                    data: this.trendData.hum_avg,
                    borderColor: '#f9eb97',
                    backgroundColor: "rgba(249, 235, 151, 0.1)",
                },
                {
                    label:'최고',
                    data: this.trendData.hum_high,
                    fill: false,
                    borderColor:"rgba(124, 9, 2, 0.4)",
                    tension: 0.1,
                    borderDash: [3, 3],
                },
                {
                    label:'최저',
                    data: this.trendData.hum_low,
                    fill: false,
                    borderColor: "rgba(93, 138, 168, 0.4)",
                    tension: 0.1,
                    borderDash: [3, 3],
                }]
            },
            this.dailyChartCo2 = {
                labels: this.trendData.time_array,

                datasets:[{
                    label:'평균',
                    data: this.trendData.co2_avg,
                    borderColor: '#a8d9f6',
                    backgroundColor: "rgba(168, 217, 246, 0.1)",
                },
                {
                    label:'최고',
                    data: this.trendData.co2_high,
                    fill: false,
                    borderColor:"rgba(124, 9, 2, 0.4)",
                    tension: 0.1,
                    borderDash: [3, 3],
                },
                {
                    label:'최저',
                    data: this.trendData.co2_low,
                    fill: false,
                    borderColor: "rgba(93, 138, 168, 0.4)",
                    tension: 0.1,
                    borderDash: [3, 3],
                }]
            },
            this.dailyChartNh3 = {
                labels: this.trendData.time_array,

                datasets:[{
                    label:'평균',
                    data: this.trendData.nh3_avg,
                    borderColor: '#1a659e',
                    backgroundColor: "rgba(26, 101, 158, 0.1)",
                },
                {
                    label:'최고',
                    data: this.trendData.nh3_high,
                    fill: false,
                    borderColor:"rgba(124, 9, 2, 0.4)",
                    tension: 0.1,
                    borderDash: [3, 3],
                },
                {
                    label:'최저',
                    data: this.trendData.nh3_low,
                    fill: false,
                    borderColor: "rgba(93, 138, 168, 0.4)",
                    tension: 0.1,
                    borderDash: [3, 3],
                }]
            },
            this.dailyChartH2s = {
                labels: this.trendData.time_array,

                datasets:[{
                    label:'평균',
                    data: this.trendData.h2s_avg,
                    borderColor: '#49a078',
                    backgroundColor: "rgba(108, 208, 168, 0.1)",
                },
                {
                    label:'최고',
                    data: this.trendData.h2s_high,
                    fill: false,
                    borderColor:"rgba(124, 9, 2, 0.4)",
                    tension: 0.1,
                    borderDash: [3, 3],
                },
                {
                    label:'최저',
                    data: this.trendData.h2s_low,
                    fill: false,
                    borderColor: "rgba(93, 138, 168, 0.4)",
                    tension: 0.1,
                    borderDash: [3, 3],
                }]
            }
        },
    },
    created(){
        // this.isMobileDevice();
    },
    mounted () {
        // this.addPlugin(ChartZoom);
    },
    methods: {
        resetGraph() {
            this.$refs.myChart.getCurrentChart().resetZoom();
        },

    }
}
</script>

<style lang="scss" scoped >
#myTempChart{
    // margin-left:20px !important;
    width: 90vw !important;
}
.chart_Layout{
    width:90vw;
    margin:10px 5px 20px 15px;
    padding-left:10px;
    padding-right:20px;
    background-color:white;
    border:1px solid #e5e5e5;
    border-radius: 20px;
    box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;

}
.reset_btn{
    background-color:#3a4f5f;
    border-radius: 50%;
    width:25px;
    height:25px;
    line-height:15px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15);
}
.title{
    text-align:center;
    font-weight:600 !important;
    font-size:15px !important;
    margin-bottom: 5px;
    letter-spacing: -1px !important;
}
</style>