<template>
  <v-container>
    <v-layout>
      <v-card width="100%" height="auto" class="mx-auto" outlined color="transparent">
        <!-- 시 간격별 데이타 조회   -->
        <v-data-table  dense height="450"
          id="time__table"
          v-show="divisionTerm < 3"
          fixed-header
          :footer-props="{
                showFirstLastPage: true,
                disableItemsPerPage: true,
                itemsPerPageOptions: [300],
              }"
          :loading="loadingTable" 
          :headers="headers"
          :items="items"
          no-data-text="측정된 자료가 없습니다."
          class="elevation-3"
          item-key="checkDateTime"
          single-select
          @click:row="selectRow"
          :sort-by= "['checkDate','checkTime']"
          :sort-desc= "[true,true]"
          :search="search"
          mobile-breakpoint="0"
        >
        </v-data-table>

        <!-- 일별 데이타 조회   -->
        <v-data-table  dense height="450"  
          v-show="divisionTerm > 2"
          fixed-header
          hide-default-footer
          :items-per-page= "1000"
          :loading="loadingTable" 
          :headers="headers_daily"
          :items="items_daily"
          no-data-text="측정된 자료가 없습니다."
          class="elevation-3"
          item-key="checkDate"
          single-select
          @click:row="selectRow"
          sort-by= "checkDate"
          :sort-desc= "true"
          :search="search"
          mobile-breakpoint="0"
          hide-default-header
          >
          <template v-slot:header="{ props }">
            <tr class="table_header" align="left">
            <th v-for="(head, i) in props.headers" :key="i" class="table_bottom header_text line">
              <td nowrap> {{ head.text0 }} </td>
            </th>
            </tr>
            <tr class="table_header" align="center">
            <th v-for="(head, i) in props.headers" :key="i" class="table_thead line">
                <td class="table_thead">  {{ head.text }} </td>
            </th>
            </tr>
          </template>

          <template v-slot:item= "{ item }">
            <tr class="content_table">
              <td>{{ item.checkDate }}</td>
              <td class="cell_high">{{ item.temp_highest }}</td>
              <td class="cell_low">{{ item.temp_lowest }}</td>
              <td class ="table_line">{{ item.temp_average }}</td>
              <td class="cell_high">{{ item.hum_highest }}</td>
              <td class="cell_low">{{ item.hum_lowest }}</td>
              <td class="table_line">{{ item.hum_average }}</td>
              <td class="cell_high">{{ item.co2_highest }}</td>
              <td class="cell_low">{{ item.co2_lowest }}</td>
              <td class="table_line">{{ item.co2_average }}</td>
              <td class="cell_high">{{ item.nh3_highest }}</td>
              <td class="cell_low">{{ item.nh3_lowest }}</td>
              <td class="table_line">{{ item.nh3_average }}</td>
              <td class="cell_high">{{ item.h2s_highest }}</td>
              <td class="cell_low">{{ item.h2s_lowest }}</td>
              <td >{{ item.h2s_average }}</td>
            </tr>
          </template>
        </v-data-table>

        <v-card-title>
          <v-text-field dense style="width:220px" class="shrink"
            clearable
            v-model="search"
            append-icon="mdi-magnify"
            label="날짜 또는 시간으로 검색"
            single-line
            hide-details
          ></v-text-field>
          
        </v-card-title>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
// import * as Xlsx from 'xlsx'
// import Apis from '@/api/apis'
// import Common from "@/utils/custom/common.js";

export default {
  name: 'ListDetail',
  props: {
    items: {
      type: Array,
    },
    items_daily: {
      type: Array,
    },
    loadingTable: Boolean,
    divisionTerm: Number,
  },

  created() {

  },

  data() {
    return {
      dialog: false,
      isMobile: false,
      search: "",
      headers: [
        { text: '측정일', align: 'center', value: 'checkDate', filterable: true, sortable: true, groupable: false, width: "120px", show: true},
        { text: '측정시간', align: 'center', value: 'checkTime', filterable: true, sortable: true, groupable: false, width: "120px",divider: true, show: true },
        { text: '온도', align: 'center', value: 'temp', filterable: false, sortable: true, groupable: false, width: "90px",divider: true, show: true },
        { text: '습도', align: 'center', value: 'hum', filterable: false, sortable: true, groupable: false, width: "90px", divider: true, show: true },
        // { text: '체감온도 ℃', align: 'end', value: 'feel', filterable: false, sortable: true, groupable: false, width: "110px",divider: true, show: true  },
        { text: '이산화탄소', align: 'center', value: 'co2', filterable: false, sortable: true, groupable: false, width: "110px",divider: true , show: true },
        { text: '암모니아', align: 'center', value: 'nh3', filterable: false, sortable: true, groupable: false, width: "110px", show: true, divider: true},
        { text: '황화수소', align: 'center', value: 'h2s', filterable: false, sortable: true, groupable: false, width: "110px", show: true },
      ],
      headers_daily: [
        { text: '측정일', align: 'center', value: 'checkDate', filterable: true, sortable: false, groupable: false, width: "115px", divider: true },

        { lb: true, text0: '',text: '최고', align: 'center', value: 'temp_highest', filterable: false, sortable: false, groupable: false, width: "50px",  divider: false },
        { text0: '온도',text: '최저', align: 'center', value: 'temp_lowest', filterable: false, sortable: false, groupable: false, width: "50px", divider: false },
        { text: '평균', align: 'center', value: 'temp_average', filterable: false, sortable: false, groupable: false, width: "60px", divider: true },

        { lb: true, text0: '',text: '최고', align: 'center', value: 'hum_highest', filterable: false, sortable: false, groupable: false, width: "60px", divider: false },
        { text0: '습도',text: '최저', align: 'center', value: 'hum_lowest', filterable: false, sortable: false, groupable: false, width: "60px", divider: false },
        { text: '평균', align: 'center', value: 'hum_average', filterable: false, sortable: false, groupable: false, width: "60px", divider: true },

        { lb: true, text0: '',text: '최고', align: 'center', value: 'co2_highest', filterable: false, sortable: false, groupable: false, width: "62px",divider: false },
        { text0: '이산화탄소', text: '최저', align: 'center', value: 'co2_lowest', filterable: false, sortable: false, groupable: false, width: "62px", divider: false },
        { text: '평균', align: 'center', value: 'co2_average', filterable: false, sortable: false, groupable: false, width: "62px", divider: true },

        { lb: true, text0: '',text: '최고', align: 'center', value: 'nh3_highest', filterable: false, sortable: false, groupable: false, width: "62px", divider: false },
        { text0: '암모니아',text: '최저', align: 'center', value: 'nh3_lowest', filterable: false, sortable: false, groupable: false, width: "62px", divider: false },
        { text: '평균', align: 'center', value: 'nh3_average', filterable: false, sortable: false, groupable: false, width: "62px", divider: false },

        { lb: true, text0: '',text: '최고', align: 'center', value: 'h2s_highest', filterable: false, sortable: false, groupable: false, width: "62px",divider: false },
        { text0: '황화수소',text: '최저', align: 'center', value: 'h2s_lowest', filterable: false, sortable: false, groupable: false, width: "62px", divider: false },
        { text: '평균', align: 'center', value: 'h2s_average', filterable: false, sortable: false, groupable: false, width: "62px", divider: false },
      ],
    }
  },
  computed: {
    // allNh3Null() {
    //   return this.listData_daily.every(item => item.nh3_highest === null);
    // },
  },
  methods: {
    selectRow(item,row) {
      row.select(true);
      // console.log("item-",item,"row=,",row)
    },
  },  // -----------methods 
}
</script>

<style lang="scss" scoped>
.v-data-table::v-deep{
  thead.v-data-table-header tr {
    th{
      background-color: #e4e8d4 !important;
      color: #3a4f3a !important;
      font-size:14px;
      letter-spacing: -0.72px;
      height:40px !important;
    }
  }
  tbody td {
    font-size: 15px !important;
    letter-spacing: -0.54px;
  }
  tbody tr{
    height:35px;
  }
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .03);
  }
}
.table_header{
  background-color: #e4e8d4 !important;
  color: #3a4f3a !important;
  font-size:14px;
  letter-spacing: -0.72px;
}
.table_thead{
  min-width: 55px !important;
}

.content_table td:first-child{
  min-width: 105px !important;
  text-align:center !important;
}
.table_line{
  border-right:1px solid lightgray !important;
}
.table_bottom:not(:first-of-type){
  border-bottom: 1px solid lightgray !important;
}
.header_text:nth-child(-n+6){
  padding-left:18px !important;
}
.header_text:nth-last-child(-n+10){
  padding-left:10px !important;
}
.line:nth-last-child(3n+4){
  border-right: 1px solid lightgray !important;
}
.cell_low{
  color: #3E4872;
}
.cell_high{
  color:#88191a;
}
</style>

