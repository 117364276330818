<template>
  <v-container text-xs-center fluid>
    <v-card rounded-pill width="1100px" class="pa-1 mb-1 mx-auto" outlined color="transparent">
      <v-form>
      <div class="sensor__info">
        <v-icon color="#3a4f3f" size="30">
          mdi-map-marker
        </v-icon>
        <span> {{ room.name }} </span>
      </div>

      <v-layout justify-center>
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dense outlined  
                class="calendar shrink rounded mt-1 ml-1 mb-1"
                id="fr_date" 
                ref="fr_date" 
                v-model="fr_date"
                label="부터"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title locale="ko-KR"
              v-model="fr_date"
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dense outlined 
                class="calendar shrink rounded mt-1 ml-1  mb-1 mr-1"
                id="to_date" 
                ref="to_date" 
                v-model="to_date"
                label="까지"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title locale="ko-KR"
              v-model="to_date"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-layout>



        <div class="custom-slider">
          <v-slider
            label="간격"
            track-color="#3a4f3f"
            color="#c1dfc4"
            :loading="loading" 
            v-model="term"
            :tick-labels="['1분','1시간','6시간','일별']"
            :max="3"
            step="1"
            ticks="always"
            @change="refreshList()"
          >
          </v-slider>

          <v-btn class="normal-btn ml-1"
            id="refreshList" 
            :loading="loading" 
            ref="refreshList" 
            type="submit" 
            @click.prevent="refreshList()">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>

        </div>
  
        <div class="btn__container" >
          <v-btn outlined
            class="show__btn mr-1"
            :disabled = "!showList"
            @click="showList = false; refreshList()"
          >
            <v-icon color=#ffffff size="20">mdi-chart-line</v-icon>
            <span class="ml-1"> 차트 </span>
            <!-- <v-icon right dark>mdi-chart-line</v-icon> -->
          </v-btn>

          <v-btn outlined
            class="show__btn"
            :disabled = "showList"
            @click="showList = true; refreshList()"
          >
            <v-icon color=#ffffff size="20">mdi-table</v-icon>
            <span class="ml-1"> 목록 </span>
            <!-- <v-icon right dark>mdi-view-list</v-icon> -->
          </v-btn>
        </div>

    </v-form>
    </v-card>

    <div class="item__container">
      <ListDetail :items="listData" :items_daily="listData_daily" :loadingTable="loading" :divisionTerm= "term" v-show="showList"  />
      <ChartDetail2 :trendData="trendData" :loadChart="loading" v-show="!showList" />
    </div>
  </v-container>
</template>

<script>
import Apis from '@/api/apis'
import VueCookies from "vue-cookies";
import dateUtil from "@/utils/custom/dateProperty.js";
import ListDetail from "./child/ListDetail.vue";
import ChartDetail2 from "./child/ChartDetail2.vue";
const term_list = [1,60,360];

export default {
  name: 'Barometer',
  components: {
    ListDetail,
    ChartDetail2,
  },
  async created() {
    Apis.pageCount({
      page_name: this.$route.name,
      url: this.$route.path,
      division: "app",
    },() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });
    await this.comboCompanies();
    await this.comboFarms();
    await this.comboBuildings();
    await this.comboRooms();

    let cdate = new Date();
    this.to_date= dateUtil.format(cdate, "yyyy-MM-dd");
    let frdate = new Date(cdate.setDate(cdate.getDate() - 6));
    this.fr_date= dateUtil.format(frdate, "yyyy-MM-dd");

    let cookie_info = VueCookies.get("barometer_info");
    if (cookie_info) {
      this.showList = cookie_info.showList;
      this.term = cookie_info.term;
    }
    if (!this.isNull(this.$route.params)) {
      // console.log("Params : ", this.$route.params);
      this.company = this.$route.params.company;
      this.farm = this.$route.params.farm;
      this.building = this.$route.params.building;
      this.room = this.$route.params.room;
    }  else if (cookie_info) {
          this.company = cookie_info["company"] || {};
          this.farm = cookie_info.farm || {};
          this.building = cookie_info.building || {};
          this.room = cookie_info.room || {};
    } else {
      this.room = this.roomItems[0];
      this.company = {code: this.room.company_cd, name: this.room.company_name};
      this.farm = {code: this.room.farm_cd, name: this.room.farm_name};
      this.building = {code: this.room.building_cd, name: this.room.building_name};
    }
    await this.refreshList();
  },
  data () {
    return {
      normal_temp: require("@/assets/barometer/normal_temp.svg"),

      active: 'chart',

      showList: false,
      // tabs: null,

      loading: false,
      company : {},
      companyItems : [],
      farm : {},
      farmItems : [],
      building : {},
      buildingItems : [],
      room : {},
      roomItems : [],

      term: 1,
      fr_date: "",
      to_date: "",

      menu1: false,
      menu2: false,

      listData: [],
      listData_daily: [],

      trendData: {},
    }
  },
  methods: {
    makeActive(item){
      // console.log(item);
			this.active = item;
		},

    async refreshList() {
      this.$store.commit("resMessage","");
      this.loading = true;
      this.listData = [];
      this.listData_daily = [];

      if (this.term < 3) {
        await Apis.room24hoursView({
          company_cd: this.company.code,
          farm_cd: this.farm.code,
          building_cd: this.building.code,
          room_cd: this.room.code,
          fr_dt: this.fr_date,
          to_dt: this.to_date,
          start_time: "00:00:00",
          end_time: "23:59:59",
          timeDivision: term_list[this.term],
          },(res) => {  
            if (res.result) {
              // console.log("resdata",res.data.datalist);
              this.listData = res.data.datalist;

              // console.log(this.listData)

              if (!this.showList) {
                // 챠트 데이타
                
                let time_temp = [];
                let temp_array = [];

                let time_hum = [];
                let hum_array = [];

                let time_co2 = [];
                let co2_array = [];

                let time_nh3 = [];
                let nh3_array = [];

                let time_h2s = [];
                let h2s_array = [];

                let w_temp_array = [];
              
                this.listData.forEach(function (item) {
                  if  (item.temp && !isNaN(item.temp)) time_temp.push(item.checkDateTime.substring(5,16));
                  if  (item.temp && !isNaN(item.temp)) temp_array.push(Number(item.temp));

                  if  (item.hum && !isNaN(item.hum)) time_hum.push(item.checkDateTime.substring(5,16));
                  if  (item.hum && !isNaN(item.hum)) hum_array.push(Number(item.hum));

                  if  (item.co2 && !isNaN(item.co2)) time_co2.push(item.checkDateTime.substring(5,16));
                  if  (item.co2 && !isNaN(item.co2)) co2_array.push(Number(item.co2));

                  if  (item.nh3 && !isNaN(item.nh3)) time_nh3.push(item.checkDateTime.substring(5,16));
                  if  (item.nh3 && !isNaN(item.nh3)) nh3_array.push(Number(item.nh3));

                  if  (item.h2s && !isNaN(item.h2s)) time_h2s.push(item.checkDateTime.substring(5,16));
                  if  (item.h2s && !isNaN(item.h2s)) h2s_array.push(Number(item.h2s));

                  if  (item.w_temp && !isNaN(item.w_temp)) w_temp_array.push(Number(item.w_temp));
                }) 

                // console.log(this.temp_array1);                
                this.trendData = {
                  time_temp : time_temp,
                  temp: temp_array,
                  
                  time_hum : time_hum,
                  hum: hum_array,

                  time_co2 : time_co2,
                  co2: co2_array,

                  time_nh3 : time_nh3,
                  nh3: nh3_array,

                  time_h2s : time_h2s,
                  h2s: h2s_array,

                  w_temp : w_temp_array
                }

                // console.log(this.trendData.h2s);
              }
          
              this.$store.commit("resMessage",res.message);
              let cookie_info = { company: this.company, farm: this.farm, building: this.building, room: this.room, showList: this.showList, term: this.term };
              VueCookies.set("barometer_info" ,cookie_info,"30d");
            } else {
              alert(res.message);
            }
            this.loading = false;

          }).catch( (err) => {  // API 오류 처리
              console.log("room24hoursView API 호출 오류",err)
              alert(err);
              this.loading = false;
          }
        )
      } else {
        await Apis.roomDailyView({
          company_cd: this.company.code,
          farm_cd: this.farm.code,
          building_cd: this.building.code,
          room_cd: this.room.code,
          fr_date: this.fr_date,
          to_date: this.to_date,
          },(res) => {  
            if (res.result) {
              this.listData_daily = res.data;
              // console.log(res.data)

              if (!this.showList) {
                // console.log("daily", res.data);
                let time_array = [];
                
                let temp_avg = [];
                let temp_low = [];
                let temp_high = [];

                let hum_avg = [];
                let hum_low = [];
                let hum_high = [];

                let co2_avg = [];
                let co2_low = [];
                let co2_high = [];

                let nh3_avg = [];
                let nh3_low = [];
                let nh3_high = [];

                let h2s_avg = [];
                let h2s_low = [];
                let h2s_high = [];

                let w_temp_avg = [];
                let w_temp_low = [];
                let w_temp_high = [];


                res.data.forEach(function (item) {
                  time_array.push(item.checkDate.substring(5));
                  if  (!isNaN(item.temp_average)) temp_avg.push( Number(item.temp_average));
                  if  (!isNaN(item.temp_highest)) temp_high.push( Number(item.temp_highest));
                  if  (!isNaN(item.temp_lowest)) temp_low.push( Number(item.temp_lowest));

                  if  (!isNaN(item.hum_average)) hum_avg.push( Number(item.hum_average));
                  if  (!isNaN(item.hum_highest)) hum_high.push( Number(item.hum_highest));
                  if  (!isNaN(item.hum_lowest)) hum_low.push( Number(item.hum_lowest));
                  
                  if  (!isNaN(item.co2_average)) co2_avg.push( Number(item.co2_average));
                  if  (!isNaN(item.co2_highest)) co2_high.push( Number(item.co2_highest));
                  if  (!isNaN(item.co2_lowest)) co2_low.push( Number(item.co2_lowest));
                  
                  if  (!isNaN(item.nh3_average)) nh3_avg.push( Number(item.nh3_average));
                  if  (!isNaN(item.nh3_highest)) nh3_high.push( Number(item.nh3_highest));
                  if  (!isNaN(item.nh3_lowest)) nh3_low.push( Number(item.nh3_lowest));
                  
                  if  (!isNaN(item.h2s_average)) h2s_avg.push( Number(item.h2s_average));
                  if  (!isNaN(item.h2s_highest)) h2s_high.push( Number(item.h2s_highest));
                  if  (!isNaN(item.h2s_lowest)) h2s_low.push( Number(item.h2s_lowest));

                  if  (!isNaN(item.w_temp_average)) w_temp_avg.push( Number(item.w_temp_average));
                  if  (!isNaN(item.w_temp_highest)) w_temp_high.push( Number(item.w_temp_highest));
                  if  (!isNaN(item.w_temp_lowest)) w_temp_low.push( Number(item.w_temp_lowest));
                  
                  
                })  

                this.trendData = {
                  time_array: time_array,
                  temp_avg: temp_avg,
                  temp_low: temp_low,
                  temp_high: temp_high,

                  hum_avg: hum_avg,
                  hum_low: hum_low,
                  hum_high: hum_high,

                  co2_avg: co2_avg,
                  co2_low: co2_low,
                  co2_high: co2_high,

                  nh3_avg: nh3_avg,
                  nh3_low: nh3_low,
                  nh3_high: nh3_high,

                  h2s_avg: h2s_avg,
                  h2s_low: h2s_low,
                  h2s_high: h2s_high,

                  w_temp_avg: w_temp_avg,
                  w_temp_high: w_temp_high,
                  w_temp_low: w_temp_low
                }

              }  
              this.$store.commit("resMessage",res.message);
              let cookie_info = { company: this.company, farm: this.farm, building: this.building, room: this.room, showList: this.showList, term: this.term };
              VueCookies.set("barometer_info" ,cookie_info,"30d");

            } else {
              alert(res.message);
            }
            this.loading = false;

          }).catch( (err) => {  // API 오류 처리
              console.log("room24hoursView API 호출 오류",err)
              alert(err);
              this.loading = false;
          }
        )
      } 
    },
    async comboCompanies() {
      await Apis.comboCompanies({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.companyItems = [];
            for (let i in res.data) {
            this.companyItems.push({
              code: res.data[i].company_cd,
              name: res.data[i].company_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboCompanies API 호출 오류",err)
        }
      ) 
    },
    changeCompany() {   // 하위 콤보 지우기;
      this.farm = {};
      this.building = {};
      this.room = {};
    },
    async comboFarms() {
      await Apis.comboFarms({
        company_cd: this.company && this.company.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.farmItems = [];
            for (let i in res.data) {
            this.farmItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              code: res.data[i].farm_cd,
              name: res.data[i].farm_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboFarms API 호출 오류",err)
        }
      ) 
    },
    changeFarm() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.farm && this.farm.company_cd) {
        this.company = {code: this.farm.company_cd, name: this.farm.company_name};
      }
        this.building = {};
        this.room = {};
    },
    async comboBuildings() {
      await Apis.comboBuildings({
        company_cd:this.company &&  this.company.code || "",
        farm_cd: this.farm && this.farm.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.buildingItems = [];
            for (let i in res.data) {
            this.buildingItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              farm_cd: res.data[i].farm_cd, 
              farm_name: res.data[i].farm_name, 
              code: res.data[i].building_cd,
              name: res.data[i].building_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboBuildings API 호출 오류",err)
        }
      ) 
    },
    changeBuilding() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.building && this.building.company_cd) {
        this.company = {code: this.building.company_cd, name: this.building.company_name};
        this.farm = {code: this.building.farm_cd, name: this.building.farm_name};
      }
        this.room = {};
    },
    async comboRooms() {
      await Apis.comboRooms({
        company_cd:this.company &&  this.company.code || "",
        farm_cd: this.farm && this.farm.code || "",
        building_cd: this.building && this.building.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.roomItems = [];
            for (let i in res.data) {
            this.roomItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              farm_cd: res.data[i].farm_cd, 
              farm_name: res.data[i].farm_name, 
              building_cd: res.data[i].building_cd, 
              building_name: res.data[i].building_name, 
              code: res.data[i].room_cd,
              name: res.data[i].room_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboRooms API 호출 오류",err)
        }
      ) 
    },
    changeRoom() {      // 하위콤보 선택시 상위 콤보 동시 세팅
      if (this.room && this.room.company_cd) {
        this.company = {code: this.room.company_cd, name: this.room.company_name};
        this.farm = {code: this.room.farm_cd, name: this.room.farm_name};
        this.building = {code: this.room.building_cd, name: this.room.building_name};
      }
    },
    summary(){
      this.$router.push({
            name: "AppSummary2",
        });
    },
    goback() { 
      window.history.back();
    },

  }  // -----------methods 

}
</script>

<style>
.v-main__wrap{
  background-color:#f9f9f9;
}
.back__btn{
  min-width: 40px !important;
  width:40px !important;
  height:40px !important; 
  border-color:transparent;
  border-radius: 50%;
  align-content: center !important;
  font-weight: bold !important;
  background-image: linear-gradient(to top, #e4e8d4 0%, #ddefdc 100%);
  margin:10px 10px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.3);
}
.sensor__info{
  display:inline-block;
  margin-top:15px;
  height:35px;
  line-height: 30px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  /* box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2); */
  border-radius: 10px;
  margin-bottom:10px;
  text-align: center;
}
.sensor__info > span {
  letter-spacing: -0.74px;
  margin-right:10px;
  font-size:18px;
  color:gray
}
.custom-slider{
  padding:5px 0 0 5px;
  /* margin-top:5px; */
  /* width:95%; */
  display:flex;
}
.v-slider__track-container {
  height: 10px !important;
}
.v-slider__track-background{
  border-radius:10px !important;
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%) !important;
}
.v-slider__track-fill{
  border-radius:10px 0 0 10px !important;
  background-image: linear-gradient(-20deg, #00cdac 0%, #8ddad5 100%) !important;

}
.v-slider__tick{
  width:0px !important;
  height: 0px !important;;
}
.v-slider__tick-label, .v-label{
  font-size:13px !important;
  letter-spacing: -1.25px;
  margin-top: 5px;
  display:block
}
.v-slider__thumb{
  width:15px !important;
  height:15px !important;
  background-color: #00cdac !important;
  /* border:1px solid white !important; */
}
.v-input__control { 
  border-radius: 100px !important; 
}
.v-text-field input {
    text-align:center;
    font-size: 0.9em !important;
}
.calendar{
  width:50%;
  height:40px;
  background-color:white;
}
.normal-btn{
  width:60px;
  height:40px;
  border-radius: 30px;
  margin-top:5px;
  align-content: center !important;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.5);
}
.normal-btn > span {
  color:white;
  letter-spacing: -0.74px;
}

.btn__container{
  display:flex;
  justify-content: center;
  margin-top:15px;
  margin-bottom:10px;
}
.show__btn{
  width:150px;
  height:40px !important;
  border-radius:20px;
  font-size:18px !important;
  letter-spacing: -2px;
  background-color:#eaeaea;
  color:#e4e4e4 !important;
  transition: all 0.3s ease 0s;
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
  border: none;
  border-radius: 45px;
  background: #e4e8d4;
  background-image: linear-gradient(to top, #e4e8d4 0%, #ddefdc 100%);
  box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.2);
  transition: all 0.3s ease 0s;
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) > .v-btn__content {
  color:#3a4f3a !important;
  font-weight:700;
  letter-spacing: -0.74px;
}

.v-btn__content{
  color:rgb(158, 158, 158);
}
.container{
  margin:0;
  padding:0;
}
.item__container{
  /* margin-top:-10px; */
  padding-left:5px;
  padding-right:5px;
}
.icon__temp{
  width:30px;
}

</style>
